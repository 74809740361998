import './recorder.scss'

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from '../../data/store'
import { SessionData } from '../../data/types'

import SimpleView from './views/simpleView'
import EngineeringView from './views/engineeringView'
import { useCurrentUser } from '../utils/useGlobalUserId'

const Recorder = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const sessionListData = useSelector((state: RootState) => state.sessions.data)

    const [sessionId, setSessionId] = useState('')
    const [isSessionActive, setIsSessionActive] = useState(false)
    const [activeSession, setActiveSession] = useState<SessionData | null>(null)
    const [isSessionOwner, setIsSessionOwner] = useState(false)
    const [previousSession, setPreviousSession] = useState<SessionData | null>(null)

    const [isSimpleViewEnabled, setIsSimpleViewEnabled] = useState<boolean>(false)

    const { currentUser } = useCurrentUser()

    const handleSimpleViewSetEnabled = (enabled: boolean) => {
        const params = new URLSearchParams(location.search)
        params.set('simpleView', enabled ? 'true' : 'false')
        navigate({ search: params.toString() }, { replace: true })
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const simpleView = params.get('simpleView')
        setIsSimpleViewEnabled(simpleView === 'true' ? true : false)
    }, [location.search])

    useEffect(() => {
        if (sessionListData) {
            const activeSessionLocal = sessionListData.find((session) => session.active === true)
            if (activeSessionLocal) {
                setActiveSession(activeSessionLocal)
                setSessionId(activeSessionLocal.id)
                setIsSessionActive(true)

                setIsSessionOwner(activeSessionLocal.owner.id === currentUser?.id)
            } else {
                // If session id is not found then the user group changed. This necessitates a forced update
                const sessionFound = sessionListData.find((session) => session.id === sessionId)
                if (!sessionFound) {
                    setPreviousSession(null)
                } else if (activeSession) {
                    setPreviousSession(activeSession)
                }
                setIsSessionActive(false)
                setActiveSession(null)
                setIsSessionOwner(false)
            }
        }
    }, [sessionListData, activeSession, sessionId, currentUser])

    return activeSession && isSimpleViewEnabled ? (
        <SimpleView
            sessionId={sessionId}
            isSessionActive={isSessionActive}
            isSessionOwner={isSessionOwner}
            activeSession={activeSession}
            isSimpleViewEnabled={isSimpleViewEnabled}
            handleSimpleViewSetEnabled={handleSimpleViewSetEnabled}
        />
    ) : (
        <EngineeringView
            sessionId={sessionId}
            isSessionActive={isSessionActive}
            isSessionOwner={isSessionOwner}
            activeSession={activeSession}
            previousSession={previousSession}
            isSimpleViewEnabled={isSimpleViewEnabled}
            handleSimpleViewSetEnabled={handleSimpleViewSetEnabled}
        />
    )
}

export default Recorder
