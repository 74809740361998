import { useState, useRef, useEffect, FC } from 'react'
import { ImplementData, MachineData } from '../../../data/types'
import { RootState } from '../../../data/store'
import { useSelector } from 'react-redux'

import './machineConfiguration.scss'
import { faTractor, faTrailer } from '@fortawesome/free-solid-svg-icons'
import { useNetworkManager } from '../../../network/networkManager'
import { EquipmentTiles } from '../../equipment/equipmentTiles'

interface MachineConfigurationProps {
    setMachineSoftwareVersions: (versions: Record<string, string>) => void
    setImplementSoftwareVersions: (versions: Record<string, string>) => void
    selectedVehicle: MachineData | null
    setSelectedVehicle: (vehicle: MachineData | null) => void
    selectedImplement: ImplementData | null
    setSelectedImplement: (implement: ImplementData | null) => void
}

const MachineConfiguration: FC<MachineConfigurationProps> = ({
    setMachineSoftwareVersions,
    setImplementSoftwareVersions,
    selectedVehicle,
    setSelectedVehicle,
    selectedImplement,
    setSelectedImplement,
}) => {
    const [machines, setMachines] = useState<MachineData[]>([])
    const [_implements, setImplements] = useState<ImplementData[]>([])
    const [showJobDropdown, setShowJobDropdown] = useState(false)
    const [showImplementDropdown, setShowImplementDropdown] = useState(false)

    const machineData = useSelector((state: RootState) => state.machines.data)
    const implementData = useSelector((state: RootState) => state._implements.data)

    const formRef = useRef<HTMLDivElement>(null)
    const implementFormRef = useRef<HTMLDivElement>(null)

    const { _implements: _implementApi, machines: machineApi } = useNetworkManager()

    useEffect(() => {
        setMachines(machineData)
    }, [machineData])

    useEffect(() => {
        setImplements(implementData)
    }, [implementData])

    useEffect(() => {
        if (!selectedVehicle && machines) {
            setSelectedVehicle(machines[0])
        }
    }, [machines, selectedVehicle, setSelectedVehicle])

    useEffect(() => {
        if (!selectedImplement && _implements) {
            setSelectedImplement(_implements[0])
        }
    }, [_implements, selectedImplement, setSelectedImplement])

    useEffect(() => {
        if (showJobDropdown && formRef.current) {
            formRef.current.style.maxHeight = `${formRef.current.scrollHeight}px`
        } else if (formRef.current) {
            formRef.current.style.maxHeight = '0'
        }
    }, [showJobDropdown])

    useEffect(() => {
        if (showImplementDropdown && implementFormRef.current) {
            implementFormRef.current.style.maxHeight = `${implementFormRef.current.scrollHeight}px`
        } else if (implementFormRef.current) {
            implementFormRef.current.style.maxHeight = '0'
        }
    }, [showImplementDropdown])

    const setMachineById = (machineId: string) => {
        const machine = machines.find((m) => m.id === machineId) || null

        if (machine) {
            setSelectedVehicle(machine)
            setShowJobDropdown(false)

            if (Array.isArray(machine.softwareVersions) && machine.softwareVersions.length > 0) {
                const initialMachineSoftwareVersions = machine.softwareVersions.reduce(
                    (acc, version) => ({ ...acc, [version.name]: version.version }),
                    {}
                )
                setMachineSoftwareVersions(initialMachineSoftwareVersions)
            } else {
                setMachineSoftwareVersions({})
            }
        } else {
            console.warn(`Machine with ID ${machineId} not found.`)
        }
    }

    const setImplementById = (implementId: string) => {
        const implement = _implements.find((m) => m.id === implementId) || null

        if (implement) {
            setSelectedImplement(implement)
            setShowImplementDropdown(false)

            if (
                Array.isArray(implement.softwareVersions) &&
                implement.softwareVersions.length > 0
            ) {
                const initialImplementSoftwareVersions = implement.softwareVersions.reduce(
                    (acc, version) => ({ ...acc, [version.name]: version.version }),
                    {}
                )
                setImplementSoftwareVersions(initialImplementSoftwareVersions)
            } else {
                setImplementSoftwareVersions({})
            }
        }
    }

    const handleCreateImplement = async (name: string, description: string) => {
        await _implementApi.addImplement(name, description)
    }

    const handleCreateMachine = async (name: string, description: string) => {
        await machineApi.addMachine(name, description)
    }

    return (
        <div className="machine-configuration-wrapper">
            <div className="equipment-selection">
                <EquipmentTiles
                    typeTitle={'Machines'}
                    typeIcon={faTractor}
                    equipment={machines}
                    selectedEquipmentId={selectedVehicle?.id}
                    onSelect={setMachineById}
                    onCreate={handleCreateMachine}
                    allowSelectionChange={true}
                    displayColumn={true}
                />
            </div>

            <div className="equipment-selection">
                <EquipmentTiles
                    typeTitle={'Implements'}
                    typeIcon={faTrailer}
                    equipment={_implements}
                    selectedEquipmentId={selectedImplement?.id}
                    onSelect={setImplementById}
                    onCreate={handleCreateImplement}
                    allowSelectionChange={true}
                    displayColumn={true}
                />
            </div>
        </div>
    )
}

export { MachineConfiguration }
