import UserManager from '../components/userManagement/userManager'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'

function App() {
    const users = useSelector((state: RootState) => state.user.data)

    return <UserManager users={users} />
}

export default App
