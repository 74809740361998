import { RootState } from '../data/store'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import ReportManager from '../components/reports/reportManager'
import { getCurrentUser } from '../data/reducers/user'
import { useAuth0 } from '@auth0/auth0-react'

function Reports() {
    const sessionsData = useSelector((state: RootState) => state.sessions.data)
    const { user: auth0User } = useAuth0()

    const [sessions, setSessions] = useState(sessionsData)

    useEffect(() => {
        setSessions(sessionsData)
    }, [sessionsData])

    const currentUser = useSelector((state: RootState) =>
        getCurrentUser(state, auth0User?.sub ?? '')
    )

    return <ReportManager sessions={sessions} user={currentUser} />
}

export default Reports
