import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddGenericFileEvent, DataState, DeviceData, ErrorPayload } from '../types'
// import { sanitizeMachinesData, sanitizeMachineData } from '../../components/utils/dataSanitizer'

const initialState: DataState<DeviceData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const dataSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchDevicesSuccess(state, action: PayloadAction<DeviceData[]>) {
            state.loading = false
            state.data = action.payload // sanitizeMachinesData(action.payload)
            state.error.message = ''
        },
        // Update the status of a single device
        updateDeviceStatus(state, action: PayloadAction<DeviceData>) {
            state.loading = false
            const updatedDevice = action.payload
            const index = state.data.findIndex(
                (device) => device.deviceId === updatedDevice.deviceId
            )

            if (index !== -1) {
                state.data[index] = {
                    ...state.data[index], // Spread existing device data
                    ...updatedDevice, // Spread new updates
                    groups:
                        Array.isArray(updatedDevice.groups) &&
                        updatedDevice.groups.every((group) => typeof group === 'object')
                            ? updatedDevice.groups
                            : state.data[index].groups, // Preserve groups if it's not a valid array
                }
            } else {
                // If the device doesn't exist, optionally add it
                state.data.push(updatedDevice)
            }
        },
        addOrUpdateDeviceFile(state, action: PayloadAction<AddGenericFileEvent>) {
            state.loading = false
            const { deviceId, file } = action.payload

            // Find the device by deviceId
            const deviceIndex = state.data.findIndex((device) => device.deviceId === deviceId)

            if (deviceIndex !== -1) {
                // Ensure the device has a files array initialized
                if (!state.data[deviceIndex].files) {
                    state.data[deviceIndex].files = []
                }

                // Find the file in the device's files array
                const fileIndex = state.data[deviceIndex].files!.findIndex(
                    (existingFile) => existingFile.id === file.id
                )

                if (fileIndex !== -1) {
                    // Update the existing file
                    state.data[deviceIndex].files![fileIndex] = file
                } else {
                    // Add the new file to the files array
                    state.data[deviceIndex].files!.push(file)
                }
            } else {
                console.error(`Device with ID ${deviceId} not found`)
            }
        },
        deleteDeviceFile(state, action: PayloadAction<{ id: string; fileId: string }>) {
            state.loading = false
            const { id, fileId } = action.payload
            const index = state.data.findIndex((device) => device.id === id)

            if (index !== -1) {
                // Remove the file from the files array
                const updatedFiles = state.data[index].files?.filter((file) => file.id !== fileId)

                // Assign the filtered files back to the device's files array
                state.data[index].files = updatedFiles || []
            } else {
                console.error(`Device with ID ${id} not found`)
            }
        },
        deleteDeviceAction(state, action: PayloadAction<{ id: string }>) {
            state.loading = false
            const { id } = action.payload
            const index = state.data.findIndex((device) => device.id === id)

            if (index !== -1) {
                // Remove the device from the devices list
                state.data.splice(index, 1)
            } else {
                console.error(`Device with ID ${id} not found`)
            }
        },
    },
})

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchDevicesSuccess,
    updateDeviceStatus,
    addOrUpdateDeviceFile,
    deleteDeviceFile,
    deleteDeviceAction,
} = dataSlice.actions
export default dataSlice.reducer
