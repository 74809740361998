import { useState, useEffect } from 'react'
import { GenericFile } from '../../../data/types'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTrashAlt, faExpand, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import './imageRenderer.css'

interface RenderImagesProps {
    images: GenericFile[] | undefined
    deleteImage: (imageId: string) => void
    imageUploading: boolean
    placeholderIcon?: IconDefinition // or IconDefinition if you want strict typing
}

export const RenderImages = ({
    images,
    deleteImage,
    imageUploading,
    placeholderIcon,
}: RenderImagesProps) => {
    const { getAccessTokenSilently } = useAuth0()

    const [expandedImage, setExpandedImage] = useState<number | null>(null)

    // Blob URLs (object URLs) in state; index correlates to `images` array
    const [thumbnailUrls, setThumbnailUrls] = useState<{ [key: number]: string }>({})
    const [fullSizeUrls, setFullSizeUrls] = useState<{ [key: number]: string }>({})

    // Loading states for each image’s thumbnail & full-size
    const [loadingThumbnails, setLoadingThumbnails] = useState<{ [key: number]: boolean }>({})
    const [loadingFullSize, setLoadingFullSize] = useState<{ [key: number]: boolean }>({})

    // Hover logic to show icons
    const [hoveredImage, setHoveredImage] = useState<number | null>(null)

    // Global loading (e.g., a spinner if images are uploading)
    const [imageLoading, setImageLoading] = useState<boolean>(false)

    useEffect(() => {
        setImageLoading(imageUploading)
    }, [imageUploading])

    useEffect(() => {
        if (!images || images.length === 0) return

        const fetchThumbnails = async () => {
            const token = await getAccessTokenSilently()

            const fetchThumbnail = async (image: GenericFile, index: number) => {
                if (!image.thumbnailUrl) return

                // Check sessionStorage for a previously-stored blob URL
                const cachedUrl = sessionStorage.getItem(`thumb-${image.id}`)
                if (cachedUrl) {
                    // Test the cached Blob URL to ensure it's still valid in this session
                    const testImg = new Image()
                    testImg.onload = () => {
                        // If it loaded fine, use it
                        setThumbnailUrls((prev) => ({ ...prev, [index]: cachedUrl }))
                    }
                    testImg.onerror = async () => {
                        // If invalid, remove it and fetch from server
                        sessionStorage.removeItem(`thumb-${image.id}`)
                        await loadThumbnailFromServer(image, index, token)
                    }
                    testImg.src = cachedUrl
                } else {
                    // If no cached URL, fetch from server
                    await loadThumbnailFromServer(image, index, token)
                }
            }

            async function loadThumbnailFromServer(
                image: GenericFile,
                index: number,
                token: string
            ) {
                setLoadingThumbnails((prev) => ({ ...prev, [index]: true }))
                try {
                    const response = await fetch(image.thumbnailUrl!, {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    if (response.ok) {
                        const blob = await response.blob()
                        const blobUrl = URL.createObjectURL(blob)
                        // Store in both React state and sessionStorage
                        setThumbnailUrls((prev) => ({ ...prev, [index]: blobUrl }))
                        sessionStorage.setItem(`thumb-${image.id}`, blobUrl)
                    } else {
                        console.error(`Failed to load thumbnail at index ${index}`)
                    }
                } catch (error) {
                    console.error(`Error fetching thumbnail at index ${index}:`, error)
                } finally {
                    setLoadingThumbnails((prev) => ({ ...prev, [index]: false }))
                }
            }

            await Promise.all(images.map((img, i) => fetchThumbnail(img, i)))
            setImageLoading(false)
        }

        fetchThumbnails()
    }, [images, getAccessTokenSilently])

    const handleImageClick = async (index: number) => {
        // If it's already expanded, collapse it
        if (expandedImage === index) {
            setExpandedImage(null)
            return
        }

        // Otherwise, expand this image
        setExpandedImage(index)

        // Load full-size image on-demand if not already loaded
        if (!fullSizeUrls[index]) {
            const token = await getAccessTokenSilently()
            await loadFullSizeImage(images![index], index, token)
        }
    }

    const loadFullSizeImage = async (image: GenericFile, index: number, token: string) => {
        if (!image.url) return

        const cachedFullUrl = sessionStorage.getItem(`full-${image.id}`)
        if (cachedFullUrl) {
            // Test whether this cached blob URL is valid
            const testImg = new Image()
            testImg.onload = () => {
                // If the blob URL is good, use it in state
                setFullSizeUrls((prev) => ({ ...prev, [index]: cachedFullUrl }))
            }
            testImg.onerror = async () => {
                // If it's invalid, remove it and fetch from server
                sessionStorage.removeItem(`full-${image.id}`)
                await loadFullSizeFromServer(image, index, token)
            }
            testImg.src = cachedFullUrl
        } else {
            // No cached URL, fetch from server
            await loadFullSizeFromServer(image, index, token)
        }
    }

    async function loadFullSizeFromServer(image: GenericFile, index: number, token: string) {
        setLoadingFullSize((prev) => ({ ...prev, [index]: true }))
        try {
            const response = await fetch(image.url!, {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            })
            if (response.ok) {
                const blob = await response.blob()
                const blobUrl = URL.createObjectURL(blob)
                // Store in state and sessionStorage
                setFullSizeUrls((prev) => ({ ...prev, [index]: blobUrl }))
                sessionStorage.setItem(`full-${image.id}`, blobUrl)
            } else {
                console.error(`Failed to load full-size image at index ${index}`)
            }
        } catch (error) {
            console.error(`Error fetching full-size image at index ${index}:`, error)
        } finally {
            setLoadingFullSize((prev) => ({ ...prev, [index]: false }))
        }
    }

    return (
        <div className="render-images">
            {(!images || images.length === 0) && placeholderIcon && (
                <div className="image-placeholder">
                    <FontAwesomeIcon icon={placeholderIcon} />
                </div>
            )}
            {images?.map((image, index) => (
                <div
                    key={index}
                    className="image-container"
                    onMouseEnter={() => setHoveredImage(index)}
                    onMouseLeave={() => setHoveredImage(null)}
                >
                    {loadingThumbnails[index] ? (
                        <div className="loading-icon">
                            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                        </div>
                    ) : (
                        <div className="image">
                            {/* Expanded (fullscreen) overlay */}
                            {expandedImage === index && (
                                <div
                                    className="fullscreen-overlay"
                                    onClick={() => setExpandedImage(null)}
                                >
                                    {loadingFullSize[index] ? (
                                        <div className="loading-icon">
                                            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                                        </div>
                                    ) : (
                                        <img
                                            src={fullSizeUrls[index] || thumbnailUrls[index]}
                                            alt={image.friendlyName}
                                            className="fullscreen-image"
                                        />
                                    )}
                                </div>
                            )}

                            {/* Thumbnail in the grid */}
                            <img
                                src={thumbnailUrls[index]}
                                alt={image.friendlyName}
                                className="thumbnail-image"
                                onClick={() => handleImageClick(index)}
                            />

                            {/* Hover controls (Expand, Delete) */}
                            {hoveredImage === index && (
                                <div className="icon-controls">
                                    <FontAwesomeIcon
                                        icon={faExpand}
                                        title="Expand Image"
                                        size="lg"
                                        className="expand-icon"
                                        onClick={() => handleImageClick(index)}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        title="Delete Image"
                                        size="lg"
                                        className="delete-icon"
                                        onClick={() => deleteImage(image.id)}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ))}

            {/* Global loading spinner if an image is being uploaded */}
            {imageLoading && (
                <div className="loading-icon">
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
            )}
        </div>
    )
}
