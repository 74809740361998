import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, GroupsData, ErrorPayload, UserData, ReporterProfile } from '../types'
import { RootState } from '../store'

const initialState: DataState<GroupsData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

interface AddUserPayload {
    groupId: string
    user: UserData
}

interface RemoveUserPayload {
    groupId: string
    userId: string
}

interface DeleteGroupPayload {
    groupId: string
}

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },
        fetchGroupsSuccess(state, action: PayloadAction<GroupsData[]>) {
            state.loading = false
            state.data = action.payload
            state.error.message = ''
        },
        addGroupSuccess(state, action: PayloadAction<GroupsData>) {
            state.loading = false
            state.data.push(action.payload)
            state.error.message = ''
        },
        addUserSuccess(state, action: PayloadAction<AddUserPayload>) {
            const { groupId, user } = action.payload
            const groupIndex = state.data.findIndex((g) => g.id === groupId)
            if (groupIndex !== -1) {
                const group = state.data[groupIndex]
                if (!group.users) {
                    group.users = []
                }
                group.users.push(user)
                state.error.message = ''
                state.loading = false
            }
        },
        removeUserSuccess(state, action: PayloadAction<RemoveUserPayload>) {
            const { groupId, userId } = action.payload
            const groupIndex = state.data.findIndex((g) => g.id === groupId)
            if (groupIndex !== -1) {
                const group = state.data[groupIndex]
                if (group.users) {
                    group.users = group.users.filter((u) => u.id !== userId)
                }
                state.error.message = ''
                state.loading = false
            }
        },
        deleteGroupSuccess(state, action: PayloadAction<DeleteGroupPayload>) {
            const { groupId } = action.payload
            state.data = state.data.filter((g) => g.id !== groupId)
            state.loading = false
            state.error.message = ''
        },
    },
})

export const getReporterProfile = (
    state: RootState,
    groupId?: string | null
): ReporterProfile | undefined => {
    if (!groupId) {
        return undefined
    }
    return state.groups.data.find((group) => group.id === groupId)?.reporterProfile
}

export const getGroup = (state: RootState, groupId?: string | null): GroupsData | undefined => {
    if (!groupId) {
        return undefined
    }
    return state.groups.data.find((group) => group.id === groupId)
}

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchGroupsSuccess,
    addGroupSuccess,
    addUserSuccess,
    removeUserSuccess,
    deleteGroupSuccess,
} = groupsSlice.actions

export default groupsSlice.reducer
