import { ImplementData, MachineData } from '../../data/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { CreationForm } from './equipmentForm'
import './equipment.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

interface EquipmentTilesProps {
    typeTitle: string
    typeIcon: IconDefinition
    equipment: MachineData[] | ImplementData[]
    selectedEquipmentId: string | undefined
    onCreate: (name: string, description: string) => Promise<void>
    onSelect: (equipmentId: string) => void
    allowSelectionChange?: boolean
    displayColumn?: boolean
}

function EquipmentTiles({
    typeTitle,
    typeIcon,
    equipment,
    selectedEquipmentId,
    onCreate,
    onSelect,
    allowSelectionChange = false,
    displayColumn = false,
}: EquipmentTilesProps) {
    const [isEditable, setIsEditable] = useState(false)

    const handleSelect = (equipment: MachineData | ImplementData) => {
        if (!allowSelectionChange || isEditable || selectedEquipmentId === null) {
            onSelect(equipment.id)
            setIsEditable(false) // Lock selection again after choosing
        }
    }

    useEffect(() => {
        setIsEditable(false)
    }, [selectedEquipmentId])

    return (
        <>
            <header>{typeTitle} Garage</header>
            <div className={`equipment-tiles ${displayColumn ? 'display-column' : ''}`}>
                {!allowSelectionChange && (
                    <CreationForm typeIcon={typeIcon} typeTitle={typeTitle} onCreate={onCreate} />
                )}

                {equipment
                    .filter(
                        (equip) =>
                            !allowSelectionChange || isEditable || equip.id === selectedEquipmentId
                    ) // Apply filtering only if allowSelectionChange is true
                    .map((equip) => (
                        <div
                            key={equip.id}
                            className={`equipment-tile ${
                                selectedEquipmentId === equip.id &&
                                (isEditable || !allowSelectionChange)
                                    ? 'selected'
                                    : ''
                            }`}
                            onClick={() => handleSelect(equip)}
                            style={{
                                cursor: allowSelectionChange
                                    ? isEditable
                                        ? 'pointer'
                                        : selectedEquipmentId === equip.id
                                        ? 'default'
                                        : 'pointer'
                                    : 'pointer',
                            }}
                        >
                            <div className="tile-header">
                                <div className="title">{equip.name}</div>

                                {!isEditable &&
                                    allowSelectionChange &&
                                    selectedEquipmentId === equip.id && (
                                        <FontAwesomeIcon
                                            icon={faWrench}
                                            className="wrench-icon"
                                            onClick={(e) => {
                                                e.stopPropagation() // Prevent triggering selection
                                                setIsEditable(true)
                                            }}
                                            title="Change selection"
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        />
                                    )}
                            </div>

                            <div className="equipment-body">
                                <div className="equipment-metadata">
                                    <div>
                                        <div style={{ fontWeight: '600' }}>Description</div>
                                        <span
                                            style={{
                                                fontWeight: '300',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {equip.description ? equip.description : 'N/A'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                {allowSelectionChange && (isEditable || equipment.length === 0) && (
                    <CreationForm typeIcon={typeIcon} typeTitle={typeTitle} onCreate={onCreate} />
                )}
            </div>
        </>
    )
}

export { EquipmentTiles }
