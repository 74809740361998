import { useState, FC, useRef, useLayoutEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'

import { useNetworkManager } from '../../network/networkManager'
import { useSessionDataHook } from '../helpers/sessionDataHelpers'
import { ConfirmationPopup } from '../misc/popup'

interface SessionConfigurationToolbarProps {
    sessionId: string
    notifyStopSuccess: () => void
}

const SessionConfigurationToolbar: FC<SessionConfigurationToolbarProps> = ({
    sessionId,
    notifyStopSuccess,
}) => {
    const { currentSoftware } = useSessionDataHook(sessionId)
    const { sessions: sessionsAPI } = useNetworkManager()

    const [isConfirmStopSessionVisible, setIsConfirmStopSessionVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [isSessionConfigurationToolbarOpen, setIsSessionConfigurationToolbarOpen] =
        useState<boolean>(false)
    const [dropdownWidth, setDropdownWidth] = useState(0)

    useLayoutEffect(() => {
        // Measure width when the dropdown changes its open state
        if (isSessionConfigurationToolbarOpen && dropdownRef.current) {
            setDropdownWidth(dropdownRef.current.scrollWidth)
        } else {
            setDropdownWidth(0)
        }
    }, [isSessionConfigurationToolbarOpen])

    const stopSessionHandler = async (confirmation: boolean) => {
        if (confirmation) {
            setLoading(true)
            await sessionsAPI.stopSession(sessionId, currentSoftware)
            setLoading(false)
            notifyStopSuccess()
        }
        setIsConfirmStopSessionVisible(false)
    }

    const toggleDropdown = () => {
        // Toggle the toolbar open state, not the confirm stop session visibility
        setIsSessionConfigurationToolbarOpen((prev) => !prev)
    }

    const endSessionHelper = () => {
        // Show the confirmation popup and close the dropdown
        setIsConfirmStopSessionVisible(true)
        setIsSessionConfigurationToolbarOpen(false)
    }

    return (
        <div
            className={`session-toolbar command-button-wrapper ${
                isSessionConfigurationToolbarOpen ? 'active' : ''
            }`}
        >
            <div
                className="dropdown-menu"
                ref={dropdownRef}
                style={{
                    width: isSessionConfigurationToolbarOpen ? `${dropdownWidth}px` : '0px',
                    visibility: isSessionConfigurationToolbarOpen ? 'visible' : 'hidden',
                }}
            >
                {isSessionConfigurationToolbarOpen && (
                    <button className="command-button" onClick={endSessionHelper}>
                        Stop Session
                    </button>
                )}
            </div>
            <div className="parent">
                <button
                    className="edit-button dropdown-button"
                    onClick={toggleDropdown}
                    title="Session Options"
                >
                    <FontAwesomeIcon icon={faGear} />
                </button>
            </div>

            {isConfirmStopSessionVisible && (
                <ConfirmationPopup
                    ChildComponent={<div>Are you sure you want to end this session?</div>}
                    callback={stopSessionHandler}
                    loading={loading}
                />
            )}
        </div>
    )
}

export default SessionConfigurationToolbar
