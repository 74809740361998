// useReporterProfile.ts
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'
import { getReporterProfile } from '../../data/reducers/groups'
import { ReporterProfile } from '../../data/types'

export function useReporterProfile(): ReporterProfile | undefined {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const groupIdFromUrl = params.get('groupId')

    const reporterProfile = useSelector((state: RootState) =>
        getReporterProfile(state, groupIdFromUrl)
    )

    return reporterProfile
}
