import '../recorder/recorder.scss'

import { Slider } from '../misc/slider'

interface SliderProps {
    enabled: boolean
    setEnabled?: (enabled: boolean) => void
    enabledText?: string
    disableText?: string
}

interface ToolData {
    label: string
    data: SliderProps
}

interface ToolbarProps {
    tools: ToolData[]
}

export const Toolbar = ({ tools }: ToolbarProps) => {
    return (
        <div className="toolbar">
            {tools.map((tool, index) => (
                <div key={index} className="tool">
                    <div className="label">{tool.label}</div>
                    <Slider
                        enabled={tool.data.enabled}
                        setEnabled={tool.data.setEnabled}
                        enabledText={tool.data.enabledText}
                        disableText={tool.data.disableText}
                    />
                </div>
            ))}
        </div>
    )
}
