import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, UserData, ErrorPayload } from '../types'
import { RootState } from '../store'

const initialState: DataState<UserData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchUserSuccess(state, action: PayloadAction<UserData | UserData[]>) {
            state.loading = false
            state.error.message = ''

            // Only wrap in an array if it's not already an array
            state.data = Array.isArray(action.payload) ? action.payload : [action.payload]
        },
    },
})

export const getCurrentUser = (state: RootState, globalUserId?: string): UserData | undefined => {
    if (!globalUserId) {
        return undefined
    }
    return state.user.data.find((user) => user.global_user_id === globalUserId)
}

export const { fetchDataRequest, fetchDataFailure, fetchUserSuccess } = usersSlice.actions
export default usersSlice.reducer
