import React, { useEffect, useState, useRef } from 'react'
import { SessionData, UserData } from '../../data/types'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    DotProps,
} from 'recharts'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import CustomTooltip from './rechartUtils'
import SessionMetadata from '../recordedSessions/SessionMetadata'
import { getIconLabel } from '../recordedSessions/eventRenderer'
import { TileView } from '../recordedSessions/tileView'

interface ReportTileProps {
    session: SessionData | null
    user?: UserData
    isGenerationAttributions: boolean
    isAttributions: boolean
    isDisplayBugList: boolean
    isAccumulator: boolean
    isDescending?: boolean
    isUtc?: boolean
}

interface CustomDotProps extends DotProps {
    dataKey: string
    lineKey: string
    stroke: string
}

const ReportTile: React.FC<ReportTileProps> = ({
    session,
    user,
    isGenerationAttributions,
    isAttributions,
    isDisplayBugList,
    isAccumulator,
    isDescending = true,
    isUtc = true,
}) => {
    const [data, setData] = useState<any[]>([])
    const [stats, setStats] = useState({ bug: 0, event: 0, update: 0 })
    const [eventsPerHour, setEventsPerHour] = useState<{
        bug: number | string
        event: number | string
        update: number | string
    } | null>({ bug: 'N/A', event: 'N/A', update: 'N/A' })
    const sessionTileRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (session) {
            const sortedSnapshots = isDescending
                ? [...session.snapshots].reverse()
                : session.snapshots

            const processDataForChart = () => {
                let bugCount = 0
                let eventCount = 0
                let updateCount = 0

                const chartData = sortedSnapshots.map((snapshot, index) => {
                    const timestamp = new Date(snapshot.initiationTimestamp)
                    const formattedTimestamp = isUtc
                        ? timestamp.toISOString().slice(11, 16) + ' UTC'
                        : timestamp.toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                          })
                    const longFormatTimestamp = isUtc
                        ? timestamp.toISOString()
                        : timestamp.toLocaleString()
                    const descriptionKey = Object.keys(snapshot.description || {})[0]

                    if (descriptionKey === 'bug') bugCount++
                    if (descriptionKey === 'event') eventCount++
                    if (descriptionKey === 'update') updateCount++
                    return {
                        timestamp: formattedTimestamp,
                        longFormatTimestamp,
                        value: {
                            bug: isAccumulator ? bugCount : descriptionKey === 'bug' ? 1 : 0,
                            event: isAccumulator ? eventCount : descriptionKey === 'event' ? 1 : 0,
                            update: isAccumulator
                                ? updateCount
                                : descriptionKey === 'update'
                                ? 1
                                : 0,
                            description: snapshot.description,
                        },
                        valueKey: descriptionKey,
                    }
                })

                setStats({ bug: bugCount, event: eventCount, update: updateCount })
                return chartData
            }

            setData(processDataForChart())
        }
    }, [session, isUtc, isDescending, isAccumulator])

    useEffect(() => {
        if (session) {
            // Calculate events per hour
            const startTime = new Date(session.startTime).getTime()
            const endTime = session.stopTime ? new Date(session.stopTime).getTime() : undefined

            if (endTime) {
                const durationInHours = (endTime - startTime) / (1000 * 60 * 60)
                setEventsPerHour({
                    bug: (stats.bug / durationInHours).toFixed(2),
                    event: (stats.event / durationInHours).toFixed(2),
                    update: (stats.update / durationInHours).toFixed(2),
                })
            } else {
                setEventsPerHour(null)
            }
        }
    }, [session, stats])

    if (!session) return <></>

    const CustomizedDot = (props: CustomDotProps) => {
        const { cx, cy, dataKey, lineKey, stroke } = props

        if (dataKey !== lineKey) {
            return null
        }

        return (
            <svg>
                <circle cx={cx} cy={cy} r={5} stroke={stroke} fill={stroke} />
            </svg>
        )
    }

    const handleDownloadPDF = () => {
        if (sessionTileRef.current) {
            html2canvas(sessionTileRef.current, {
                scrollX: 0,
                scrollY: -window.scrollY,
                width: sessionTileRef.current.scrollWidth,
                height: sessionTileRef.current.scrollHeight,
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF('p', 'mm', 'a4')
                const pdfWidth = pdf.internal.pageSize.getWidth()
                const pdfHeight = pdf.internal.pageSize.getHeight()

                // Image properties
                const imgProps = pdf.getImageProperties(imgData)
                const imgWidth = imgProps.width
                const imgHeight = imgProps.height
                const ratio = pdfWidth / imgWidth
                const scaledHeight = imgHeight * ratio

                // Calculate the number of pages needed
                const totalPages = Math.ceil(scaledHeight / pdfHeight)

                for (let i = 0; i < totalPages; i++) {
                    const position = -i * pdfHeight
                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, scaledHeight)

                    if (i < totalPages - 1) {
                        pdf.addPage()
                    }
                }

                pdf.save('session_report.pdf')
            })
        }
    }

    return (
        <div className="report-body session-tile">
            <button onClick={handleDownloadPDF}>Download PDF</button>
            <div ref={sessionTileRef}>
                <div className="tile-header">
                    <div>{session.title}</div>

                    {isGenerationAttributions && (
                        <div className="generation-attribution">
                            Generated by: {user?.given_name} {user?.family_name}
                        </div>
                    )}
                </div>
                <div className="chart-container">
                    <ResponsiveContainer width="100%" height={600}>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="timestamp"
                                angle={-45}
                                textAnchor="end"
                                allowDecimals={false}
                                tickCount={20}
                                height={100}
                            />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="value.bug"
                                stroke="#aa2626"
                                dot={(props) => (
                                    <CustomizedDot
                                        key={`bug-dot-${props.index}`}
                                        cx={props.cx}
                                        cy={props.cy}
                                        dataKey={props.payload.valueKey}
                                        lineKey="bug"
                                        stroke="#aa2626"
                                    />
                                )}
                            />
                            <Line
                                type="monotone"
                                dataKey="value.event"
                                stroke="#e7b529"
                                dot={(props) => (
                                    <CustomizedDot
                                        key={`event-dot-${props.index}`}
                                        cx={props.cx}
                                        cy={props.cy}
                                        dataKey={props.payload.valueKey}
                                        lineKey="event"
                                        stroke="#e7b529"
                                    />
                                )}
                            />
                            <Line
                                type="monotone"
                                dataKey="value.update"
                                stroke="#0e6d16"
                                dot={(props) => (
                                    <CustomizedDot
                                        key={`update-dot-${props.index}`}
                                        cx={props.cx}
                                        cy={props.cy}
                                        dataKey={props.payload.valueKey}
                                        lineKey="update"
                                        stroke="#0e6d16"
                                    />
                                )}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className="summary">
                    <div className="stats">
                        <div className="group">
                            <div className="title">Events (Absolute)</div>
                            <div className="data">
                                {Object.entries(stats).map(([key, value]) => (
                                    <div className="entry" key={key}>
                                        {getIconLabel(key)}: {value}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="group">
                            <div className="title">Events (Per Hour)</div>
                            <div className="data">
                                {eventsPerHour
                                    ? Object.entries(eventsPerHour).map(([key, value]) => (
                                          <div className="entry" key={key}>
                                              {getIconLabel(key)}:{' '}
                                              {typeof value === 'number' ? value.toFixed(2) : value}
                                          </div>
                                      ))
                                    : 'N/A - No recorded stop time'}
                            </div>
                        </div>
                    </div>
                    <SessionMetadata
                        session={session}
                        isAttributions={isAttributions}
                        isUtc={isUtc}
                    />

                    {isDisplayBugList &&
                        session.snapshots.map((snapshot) => (
                            <TileView
                                key={snapshot.id}
                                session={session}
                                snapshot={snapshot}
                                isAttributions={isAttributions}
                                isUtc={isUtc}
                                isDescending={isDescending}
                                isToolbarEnabled={false}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default ReportTile
