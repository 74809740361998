import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'
import { getCurrentUser } from '../../data/reducers/user'
import { GroupsData, UserData } from '../../data/types'
import { useLocation } from 'react-router-dom'
import { getGroup } from '../../data/reducers/groups'

function useParseGlobalUserId(): string | undefined {
    const { user } = useAuth0()

    // Parse globalUserId from Auth0 user.sub
    let globalUserId: string | undefined
    if (user?.sub) {
        const parts = user.sub.split('|')
        globalUserId = parts[parts.length - 1]
    }
    return globalUserId
}

interface UseCurrentUserReturn {
    currentUser: UserData | undefined
    isAdmin: boolean
}

export function useCurrentUser(): UseCurrentUserReturn {
    const globalUserId = useParseGlobalUserId()
    const currentUser = useSelector((state: RootState) => getCurrentUser(state, globalUserId))
    const isAdmin = currentUser?.roles.includes('admin') || false
    return { currentUser, isAdmin }
}

export function useGlobalUserId(): string | undefined {
    const globalUserId = useParseGlobalUserId()

    return globalUserId
}

interface UseGroupReturn {
    group: GroupsData | undefined
    groupId: string
}
export function useGroup(): UseGroupReturn {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const groupId = params.get('groupId') || ''

    const group = useSelector((state: RootState) => getGroup(state, groupId))

    return { group, groupId }
}
