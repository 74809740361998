import { FC, useEffect, useState } from 'react'
import { GroupsData, UserData } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRemove, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useNetworkManager } from '../../network/networkManager'

import './userManagement.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'

interface UserManagerProps {
    users: UserData[]
}

const UserManager: FC<UserManagerProps> = ({ users }) => {
    const [newGroupName, setNewGroupName] = useState<string>('')
    const [expandedUsers, setExpandedUsers] = useState<{ [userId: string]: boolean }>({})
    const [selectedGroupsForUsers, setSelectedGroupsForUsers] = useState<{
        [userId: string]: string
    }>({})
    const [groups, setGroups] = useState<GroupsData[]>([])

    const { groups: groupsAPI } = useNetworkManager()

    const groupsData = useSelector((state: RootState) => state.groups.data)
    useEffect(() => {
        setGroups(groupsData || [])
    }, [groupsData])

    const handleCreateGroup = async () => {
        await groupsAPI.addGroup(newGroupName)
    }

    const handleDeleteGroup = async (groupId: string) => {
        await groupsAPI.deleteGroup(groupId)
    }

    const handleAddGroupToUser = async (userId: string) => {
        const groupId = selectedGroupsForUsers[userId]
        if (groupId) {
            const group = groups?.find((group) => group.id === groupId)
            if (group && !group.users?.some((user) => user.id === userId)) {
                await groupsAPI.addUser(userId, groupId)
            }
            setSelectedGroupsForUsers({ ...selectedGroupsForUsers, [userId]: '' })
        }
    }

    const handleRemoveUserFromGroup = async (userId: string, groupId: string) => {
        const group = groups?.find((group) => group.id === groupId)
        if (group && group.users?.some((user) => user.id === userId)) {
            await groupsAPI.removeUser(userId, groupId)
        }
    }

    const toggleUserGroups = (userId: string) => {
        setExpandedUsers((prev) => ({ ...prev, [userId]: !prev[userId] }))
    }

    const sortedUsers = [...users].sort((a, b) => {
        const nameA = `${a.given_name} ${a.family_name}`.toLowerCase()
        const nameB = `${b.given_name} ${b.family_name}`.toLowerCase()
        return nameA.localeCompare(nameB)
    })

    const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name))

    return (
        <div className="user-manager-wrapper">
            <div className="user-management">
                <div className="modify-groups">
                    <div className="new-group">
                        <div className="title">New Group:</div>
                        <div className="input">
                            <input
                                value={newGroupName}
                                onChange={(e) => setNewGroupName(e.target.value)}
                            />
                            <button onClick={handleCreateGroup}>Create Group</button>
                        </div>
                    </div>
                    <div className="existing-groups">
                        <div className="title">Existing Groups:</div>
                        <div className="groups">
                            {sortedGroups.map((group, key) => (
                                <div key={key}>
                                    <div>{group.name}</div>
                                    <button onClick={() => handleDeleteGroup(group.id)}>
                                        Delete
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="user-management">
                <div className="user-list">
                    <div className="user no-divider">
                        <div className="metadata">
                            <div className="user-name">Name</div>
                            <div className="user-roles">
                                <div className="title">Roles</div>
                            </div>
                            <div className="groups">
                                <div className="title">Groups</div>
                            </div>
                        </div>
                    </div>
                    {sortedUsers.map((user) => (
                        <div key={user.id} className="user">
                            <div className="metadata">
                                <div className="user-name">
                                    {user.given_name} {user.family_name}
                                </div>
                                <div className="user-roles">
                                    <div className="roles">
                                        {user.roles.map((role, index) => (
                                            <div key={index}>{`${
                                                index !== 0 ? ', ' : ''
                                            } ${role}`}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="groups">
                                    <button
                                        className="toggle-groups-button"
                                        onClick={() => toggleUserGroups(user.id)}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                expandedUsers[user.id]
                                                    ? faChevronDown
                                                    : faChevronRight
                                            }
                                        />
                                        Groups
                                    </button>
                                    {expandedUsers[user.id] && (
                                        <div className="group-list">
                                            {sortedGroups
                                                .filter((group) =>
                                                    group.users?.some((u) => u.id === user.id)
                                                )
                                                .map((group) => (
                                                    <div key={group.id} className="group-entry">
                                                        <div className="group-item">
                                                            {group.name}
                                                        </div>
                                                        <span className="delete-entry">
                                                            <FontAwesomeIcon
                                                                icon={faRemove}
                                                                onClick={() =>
                                                                    handleRemoveUserFromGroup(
                                                                        user.id,
                                                                        group.id
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="add-group-to-user">
                                {sortedGroups.some(
                                    (group) => !group.users?.some((u) => u.id === user.id)
                                ) ? (
                                    <>
                                        <select
                                            value={selectedGroupsForUsers[user.id] || ''}
                                            onChange={(e) =>
                                                setSelectedGroupsForUsers({
                                                    ...selectedGroupsForUsers,
                                                    [user.id]: e.target.value,
                                                })
                                            }
                                        >
                                            <option value="">Select Group</option>
                                            {sortedGroups
                                                .filter(
                                                    (group) =>
                                                        !group.users?.some((u) => u.id === user.id)
                                                )
                                                .map((group) => (
                                                    <option key={group.id} value={group.id}>
                                                        {group.name}
                                                    </option>
                                                ))}
                                        </select>
                                        <button
                                            onClick={() => handleAddGroupToUser(user.id)}
                                            disabled={!selectedGroupsForUsers[user.id]}
                                        >
                                            Add to Group
                                        </button>
                                    </>
                                ) : (
                                    <span className="no-groups-available">
                                        User already in all groups
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default UserManager
