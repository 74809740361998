import { AppDispatch } from '../../data/store'
import {
    fetchDataRequest,
    fetchDataFailure,
    fetchMachinesSuccess,
    addMachineSuccess,
    editMachineSuccess,
    deleteMachineSuccess,
} from '../../data/reducers/machines'
import { ApolloClient, NormalizedCacheObject, gql, ApolloError } from '@apollo/client'
import { SoftwareVersions } from '../../data/types'

export const fetchMachines = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_MACHINES = gql`
        query GetMachines {
            machines {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_MACHINES,
        })
        dispatch(fetchMachinesSuccess(response.data.machines))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const addMachine = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    name: string,
    description: string
) => {
    const ADD_MACHINE = gql`
        mutation AddMachine($name: String!, $description: String) {
            addMachine(name: $name, description: $description) {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: ADD_MACHINE,
            variables: { name, description },
        })
        dispatch(addMachineSuccess(response.data.addMachine))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const editMachine = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    name: string,
    description: string,
    softwareVersions: SoftwareVersions[]
) => {
    const EDIT_MACHINE = gql`
        mutation EditMachine(
            $id: ID!
            $name: String!
            $description: String
            $softwareVersions: [SoftwareVersionInput]
        ) {
            editMachine(
                id: $id
                name: $name
                description: $description
                softwareVersions: $softwareVersions
            ) {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: EDIT_MACHINE,
            variables: { id, name, description, softwareVersions },
        })
        dispatch(editMachineSuccess(response.data.editMachine))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const deleteMachine = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string
) => {
    const DELETE_MACHINE = gql`
        mutation DeleteMachine($id: ID!) {
            deleteMachine(id: $id) {
                id
                name
                description
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: DELETE_MACHINE,
            variables: { id },
        })
        dispatch(deleteMachineSuccess(response.data.deleteMachine))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}
