import { FC } from 'react'

interface EquipmentSoftwareProps {
    type: 'machine' | '_implement'
    equipmentName: string
    softwareVersions: Record<string, string>
    onVersionChange: (type: string, software: string, version: string) => void
    reporterProfile: any // Adjust type as needed
}

const EquipmentSoftware: FC<EquipmentSoftwareProps> = ({
    type,
    equipmentName,
    softwareVersions,
    onVersionChange,
    reporterProfile,
}) => {
    // Get the correct list of software based on type
    const softwareList = Object.keys(
        reporterProfile?.loggingConfiguration?.update?.[
            type === 'machine' ? 'Machines' : 'Implements'
        ] || {}
    ).filter((key) => !key.startsWith('__')) // Exclude special keys

    return (
        <div className="software-versions">
            <div style={{ fontWeight: '600' }}>{equipmentName} Software:</div>
            {softwareList.map((software) => (
                <div className="software-item" key={software}>
                    <div className="title">{software}</div>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={softwareVersions[software] || ''}
                            onChange={(e) => onVersionChange(type, software, e.target.value)}
                            placeholder="Enter version"
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

export { EquipmentSoftware }
