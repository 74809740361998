import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import { clearAuthParams } from '../utils/authStore'
import { useEffect, useState } from 'react'

import './header.css'
import { useCurrentUser } from '../utils/useGlobalUserId'

function Header() {
    const location = useLocation()
    const navigate = useNavigate()
    const { logout } = useAuth0()

    const [selectedGroupId, setSelectedGroupId] = useState('')

    const { currentUser } = useCurrentUser()

    const title = window.innerWidth > 800 ? 'Field Event Reporter' : 'F.E.R.'

    useEffect(() => {
        // Check URL for groupId
        const params = new URLSearchParams(location.search)
        const groupIdFromUrl = params.get('groupId')
        if (groupIdFromUrl) {
            setSelectedGroupId(groupIdFromUrl)
        }
    }, [location.search])

    const baseURL = process.env.REACT_APP_FRONTEND_SERVER_URL

    const logoutUser = () => {
        clearAuthParams()

        logout({
            logoutParams: {
                returnTo: baseURL,
            },
        })
    }

    const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newGroupId = event.target.value

        // Update URL search param instead of local storage
        const params = new URLSearchParams(location.search)
        params.set('groupId', newGroupId)
        navigate({ search: params.toString() }, { replace: true })
    }

    return (
        <div id="header">
            <div className="title">{title}</div>

            <div className="right-hand-components">
                <div className="group-id-select">
                    {currentUser && currentUser.groups && (
                        <select
                            value={selectedGroupId}
                            onChange={handleGroupChange}
                            title="Selected Group"
                        >
                            {currentUser.groups.map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="logout-button-wrapper">
                    <button id="logout-button" onClick={logoutUser} title="Logout">
                        <FontAwesomeIcon icon={faSignOut} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Header
