import React, { useState } from 'react'
import { SessionData } from '../../data/types'
import { useNetworkManager } from '../../network/networkManager'
import { ConfirmationPopup } from '../misc/popup'
import SessionTile from './sessionTile'
import './sessionTiles.css'
import { Toolbar } from '../toolbar/toolbar'
import { selectActiveSession } from '../../data/reducers/sessions'
import { useSelector } from 'react-redux'

interface SessionTilesProps {
    sessions: SessionData[] | []
    scrollToEvent: string
}

const SessionTiles: React.FC<SessionTilesProps> = ({ sessions, scrollToEvent = '' }) => {
    const { sessions: sessionsAPI } = useNetworkManager()
    const [isResumeDialogueVisible, setIsResumeDialogueVisible] = useState<boolean>(false)
    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sessionIdToResume, setSessionIdToResume] = useState<string | null>(null)
    const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(null)
    const [isLocalTime, setIsLocalTime] = useState<boolean>(false)
    const [isDescendingSessionOrder, setIsDescendingSessionOrder] = useState<boolean>(true)
    const [isDescendingEventOrder, setIsDescendingEventOrder] = useState<boolean>(true)
    const activeSession = useSelector(selectActiveSession)

    const resumeSession = async (sessionId: string) => {
        if (activeSession?.id) {
            alert(`Session ${activeSession.title} is already active!`)
            return
        }
        setSessionIdToResume(sessionId)
        setIsResumeDialogueVisible(true)
    }

    const resumeHandler = async (confirmation: boolean) => {
        if (confirmation && sessionIdToResume) {
            setLoading(true)
            await sessionsAPI.resumeSession(sessionIdToResume)
            setLoading(false)
            setSessionIdToResume(null)
        }
        setIsResumeDialogueVisible(false)
    }

    const deleteSession = async (sessionId: string) => {
        setSessionIdToDelete(sessionId)
        setIsDialogueVisible(true)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && sessionIdToDelete) {
            setLoading(true)
            await sessionsAPI.deleteSession(sessionIdToDelete, true)
            setLoading(false)
            setSessionIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const sortedSessions = isDescendingSessionOrder ? [...sessions].reverse() : sessions

    return (
        <div className="session-tile-wrapper">
            {isResumeDialogueVisible && (
                <ConfirmationPopup
                    ChildComponent={<div>Are you sure you want to resume this session?</div>}
                    callback={resumeHandler}
                    loading={loading}
                />
            )}
            {isDialogueVisible && (
                <ConfirmationPopup
                    ChildComponent={<div>Are you sure you want to delete this session?</div>}
                    callback={deleteHandler}
                    loading={loading}
                />
            )}
            <div className="session-list">
                <Toolbar
                    tools={[
                        {
                            label: 'Descending Session Order',
                            data: {
                                enabled: isDescendingSessionOrder,
                                setEnabled: setIsDescendingSessionOrder,
                            },
                        },
                        {
                            label: 'Descending Event Order',
                            data: {
                                enabled: isDescendingEventOrder,
                                setEnabled: setIsDescendingEventOrder,
                            },
                        },
                        {
                            label: 'Local time',
                            data: {
                                enabled: isLocalTime,
                                setEnabled: setIsLocalTime,
                            },
                        },
                    ]}
                />
                {sortedSessions.length > 0 ? (
                    sortedSessions.map((session) => (
                        <SessionTile
                            key={session.id}
                            session={session}
                            onResume={activeSession?.id ? undefined : resumeSession}
                            onDelete={deleteSession}
                            isUtc={!isLocalTime}
                            isDescending={isDescendingEventOrder}
                            scrollToEvent={scrollToEvent}
                        />
                    ))
                ) : (
                    <div>No Recorded Sessions</div>
                )}
            </div>
        </div>
    )
}

export default SessionTiles
