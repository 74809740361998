import '../recorder.scss'
import './simpleSnapshotManager.css'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faExclamationTriangle, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { OptionTree } from '../../optionTree/optionTree'
import { useSelector } from 'react-redux'
import { RootState } from '../../../data/store'

interface SimpleSnapshotManagerProps {
    onReportEvent: (
        initiationTimestamp: string,
        data: Record<string, any>,
        classification: string
    ) => Promise<boolean | undefined>
    defaultOptions?: Record<string, any>
    isEdit?: boolean
    onClose?: () => void
}

const SimpleSnapshotManager = ({
    onReportEvent,
    defaultOptions,
    isEdit,
    onClose,
}: SimpleSnapshotManagerProps) => {
    const [rootOption, setRootOption] = useState<string>('')
    const [initiationTimestamp, setInitiationTimestamp] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedOptions, setSelectedOptions] = useState<Record<string, any>>({})
    const [inputValues, setInputValues] = useState<Record<string, string>>({})
    const [errorMessage, setErrorMessage] = useState<string>('')

    const groupData = useSelector((state: RootState) => state.groups.data)

    const [recorderOptions, setRecorderOptions] = useState<Record<string, any>>({})

    // when state changes set the options to the logging configuration
    useEffect(() => {
        if (groupData && groupData.length > 0) {
            setRecorderOptions(groupData[0].reporterProfile?.loggingConfiguration)
        }
    }, [groupData])

    // when default options change then reset the root option to the first default option
    useEffect(() => {
        if (defaultOptions) {
            setRootOption(Object.keys(defaultOptions)[0] || '')
        } else {
            setRootOption('')
        }
    }, [defaultOptions])

    // when default options or root option changes then set the default selected options to the child node of the selected root option
    const defaultSelectedOptions: Record<string, any> = useMemo(() => {
        if (defaultOptions) {
            return defaultOptions[rootOption]
        } else {
            return undefined
        }
    }, [defaultOptions, rootOption])

    // when recorder options or root option changes then set the available options to the child node of the selected root option
    const availableOptions: Record<string, any> = useMemo(() => {
        if (rootOption) {
            return { ...recorderOptions[rootOption] }
        }
        return {}
    }, [recorderOptions, rootOption])

    const showBugOptions = rootOption === 'bug'
    const showEventOptions = rootOption === 'event'
    const showSoftwareUpdateOptions = rootOption === 'update'

    type SelectedOptionInputs = {
        selectedOption: string
        isOptionSelectionRequired: boolean
        inputs: Record<string, any>
    }

    const selectedOptionInputs: SelectedOptionInputs[] = useMemo(() => {
        let inputs: SelectedOptionInputs[] = []

        if (Object.keys(availableOptions).length > 0) {
            // render any inputs from the root of the available options first
            let options = availableOptions
            inputs.push({
                selectedOption: '',
                isOptionSelectionRequired: options.__attributes?.required,
                inputs: options.__inputs,
            })

            // record each selected option and add any inputs from its child options
            let selectedOption = selectedOptions
            let selectedOptionKey = Object.keys(selectedOption)[0]
            while (selectedOptionKey) {
                inputs[inputs.length - 1].selectedOption = selectedOptionKey
                options = options[selectedOptionKey]
                inputs.push({
                    selectedOption: '',
                    isOptionSelectionRequired: options.__attributes?.required,
                    inputs: options.__inputs,
                })
                selectedOption = selectedOption[selectedOptionKey]
                selectedOptionKey = Object.keys(selectedOption)[0]
            }
        }

        return inputs
    }, [selectedOptions, availableOptions])

    const showErrorMessage = async (message: string) => {
        setErrorMessage(message)
        await setTimeout(() => setErrorMessage(''), 2000)
    }

    // Update initiation timestamp whenever a form is opened
    const updateInitiationTimestamp = () => {
        setInitiationTimestamp(new Date().toISOString())
    }

    const handleBugClick = () => {
        setRootOption('bug')
        setSelectedOptions({})
        updateInitiationTimestamp()
    }

    const handleEventClick = () => {
        setRootOption('event')
        setSelectedOptions({})
        updateInitiationTimestamp()
    }

    const handleSoftwareUpdateClick = () => {
        setRootOption('update')
        setSelectedOptions({})
        updateInitiationTimestamp()
    }

    const eventSubmitted = () => {
        setRootOption('')
        setSelectedOptions({})
        setInputValues({})
    }

    const logDataAndNotify = async (data: Record<string, any>, classification: string) => {
        const success = await onReportEvent(initiationTimestamp, data, classification)
        if (success) {
            eventSubmitted()
        }
        return success
    }

    const handleOptionTreeOnChange = useCallback((value: Record<string, any>) => {
        setSelectedOptions(value)
    }, [])

    const handleInputChange = useCallback((prompt: string, value: string) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [prompt]: value,
        }))
    }, [])

    const findFirstLeafNode = useCallback(
        (obj: Record<string, any>): Record<string, any> | undefined => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        const result = findFirstLeafNode(obj[key])
                        if (result !== undefined) {
                            return result
                        } else {
                            return { [key]: obj[key] }
                        }
                    }
                }
            }

            return undefined
        },
        []
    )

    // when default options change then set default input values
    useEffect(() => {
        let defaultInputValues: Record<string, string> = {}
        if (defaultOptions) {
            // input values are properties of the leaf object
            const leaf = findFirstLeafNode(defaultOptions)
            if (leaf) {
                defaultInputValues = leaf[Object.keys(leaf)[0]]
            }
        }
        setInputValues(defaultInputValues)
    }, [defaultOptions, findFirstLeafNode])

    const submitEvent = async (classification: string) => {
        // wrap a deep copy of selected options in a new object keyed by classification
        let data: Record<string, any> = {
            [classification]: JSON.parse(JSON.stringify(selectedOptions)),
        }

        let selectedInputValues: Record<string, string> = {}
        if (selectedOptionInputs.length > 0) {
            const lastSelectedOptionInput = selectedOptionInputs[selectedOptionInputs.length - 1]
            if (
                lastSelectedOptionInput.isOptionSelectionRequired &&
                '' === lastSelectedOptionInput.selectedOption
            ) {
                showErrorMessage('You must select an option.')
                return
            }
            for (const selectedOptionInput of selectedOptionInputs) {
                if (selectedOptionInput.inputs) {
                    for (const [key, input] of Object.entries(selectedOptionInput.inputs)) {
                        const value = inputValues[key]
                        if (value !== null && value !== undefined && value !== '') {
                            selectedInputValues[key] = value
                        } else if (input.required) {
                            showErrorMessage(`${key} is required.`)
                            return
                        }
                    }
                }
            }
        }

        let leaf = findFirstLeafNode(data)
        if (leaf) {
            if (Object.keys(selectedInputValues).length > 0) {
                Object.assign(leaf[Object.keys(leaf)[0]], selectedInputValues)
            } else {
                // graphql needs a description object that isn't empty so add a placeholder to the first leaf
                leaf[Object.keys(leaf)[0]].placeholder = '<no input provided>'
            }
        }

        setLoading(true) // Disable the form and show spinner

        await logDataAndNotify(data, classification)

        setLoading(false)
    }

    const inputs = useMemo(() => {
        let inputs: Record<string, any> = {}

        for (const selectedOptionInput of selectedOptionInputs) {
            if (selectedOptionInput.inputs) {
                for (const [key, input] of Object.entries(selectedOptionInput.inputs)) {
                    if (key in inputs) {
                        console.error(`Duplicate input "${key}"!`)
                    }
                    inputs[key] = input
                }
            }
        }

        return inputs
    }, [selectedOptionInputs])

    const Inputs = () => {
        return (
            Object.keys(inputs).length > 0 && (
                <div className="input-container">
                    {Object.entries(inputs).map(([key, input]) => {
                        return (
                            <div key={key}>
                                <label>
                                    {key}
                                    {input.required && (
                                        <span className="required-indicator">*</span>
                                    )}
                                </label>
                                {input.type !== 'textarea' ? (
                                    <input
                                        type={input.type}
                                        value={inputValues[key] || ''}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        disabled={loading}
                                        placeholder={input.placeholder}
                                        required={input.required}
                                        className={!inputValues[key] ? 'required-pseudo' : ''}
                                    />
                                ) : (
                                    <textarea
                                        value={inputValues[key] || ''}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        disabled={loading}
                                        placeholder={input.placeholder}
                                        required={input.required}
                                        className={!inputValues[key] ? 'required-pseudo' : ''}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            )
        )
    }

    const ErrorMessage = () => {
        return errorMessage && <label className="error-message small-font">{errorMessage}</label>
    }

    return (
        <div className="recording-icons actions option-container">
            <div className="option-buttons">
                <div
                    className={`icon-button bug ${showBugOptions ? 'active' : ''} option-button ${
                        showBugOptions ? 'highlight' : ''
                    }`}
                    onClick={() => {
                        if (!loading) {
                            handleBugClick()
                        }
                    }}
                >
                    <div className="icon">
                        <FontAwesomeIcon icon={faBug} />
                    </div>
                    <span style={{ paddingLeft: '10px' }}>Bug</span>
                </div>
                <div
                    className={`icon-button event ${
                        showEventOptions ? 'active' : ''
                    } option-button ${showEventOptions ? 'highlight' : ''}`}
                    onClick={() => {
                        if (!loading) {
                            handleEventClick()
                        }
                    }}
                >
                    <div className="icon">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </div>
                    <span style={{ paddingLeft: '10px' }}>Event</span>
                </div>
                <div
                    className={`icon-button update ${
                        showSoftwareUpdateOptions ? 'active' : ''
                    } option-button ${showSoftwareUpdateOptions ? 'highlight' : ''}`}
                    onClick={() => {
                        if (!loading) {
                            handleSoftwareUpdateClick()
                        }
                    }}
                >
                    <div className="icon">
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </div>
                    <span style={{ paddingLeft: '10px' }}>Software Update</span>
                </div>
            </div>
            <OptionTree
                options={availableOptions}
                defaultOptions={defaultSelectedOptions}
                onChange={handleOptionTreeOnChange}
                disabled={loading}
            />
            {selectedOptionInputs[selectedOptionInputs.length - 1]?.isOptionSelectionRequired &&
                '' === selectedOptionInputs[selectedOptionInputs.length - 1]?.selectedOption && (
                    <label className="required-indicator small-font">
                        Option Selection Required
                    </label>
                )}
            {Inputs()}
            <div className="option-buttons">
                {showBugOptions && (
                    <button
                        className={'icon-button option-button'}
                        onClick={() => submitEvent('bug')}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner"></span>
                        ) : (
                            `${isEdit ? 'Save' : 'Log'} Bug`
                        )}
                    </button>
                )}
                {showEventOptions && (
                    <button
                        className={'icon-button option-button'}
                        onClick={() => submitEvent('event')}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner"></span>
                        ) : (
                            `${isEdit ? 'Save' : 'Log'} Event`
                        )}
                    </button>
                )}
                {showSoftwareUpdateOptions && (
                    <button
                        className={'icon-button option-button'}
                        onClick={() => submitEvent('update')}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner"></span>
                        ) : (
                            `${isEdit ? 'Save' : 'Log'} Software Update`
                        )}
                    </button>
                )}
                {onClose && (
                    <button
                        className={'icon-button option-button'}
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </button>
                )}
            </div>
            {ErrorMessage()}
        </div>
    )
}

export default SimpleSnapshotManager
